"use client";

import * as React from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import Script from "next/script";
import { getCookie, hasCookie, setCookie } from "cookies-next";
import { isAfter, parseISO } from "date-fns";

import { mixpanelScript, clarityScript, gtmScript, useClarity } from "@lib/analytics-tracking";

export default function EntryPoint({ subscription, user }) {
	useClarity(user);
	const router = useRouter();
	const pathname = usePathname();
	const searchParam = useSearchParams();
	const [noGtm, setNoGtm] = React.useState(false);
	const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
	const bentoSiteUuid = process.env.NEXT_PUBLIC_BENTO_SITE_UUID;
	const easyaskApiKey = process.env.NEXT_PUBLIC_EASYASK_API_KEY;
	const paid = subscription?.is_active ? "paid" : "free";

	React.useEffect(() => {
		const saveCKId = () => {
			const ckSubscriberId = searchParam.get("ck_subscriber_id");
			if (typeof window === "undefined") {
				return false;
			}

			window.localStorage.setItem("ck_subscriber_id", ckSubscriberId);

			router.replace(pathname, { scroll: false });
		};

		if (searchParam.has("ck_subscriber_id")) {
			saveCKId();
		}
	}, [pathname, router, searchParam]);

	React.useEffect(() => {
		if (typeof window !== "undefined" && window.location.href.indexOf("no_gtm") !== -1) {
			setNoGtm(true);
			return;
		}

		setNoGtm(false);
	}, []);

	// track the users previous and current path
	React.useEffect(() => {
		const storage = window?.sessionStorage;
		if (!storage) return;
		const isAuthPath = ["/login/", "/signup/", "/forgot-password/"].includes(
			storage.getItem("currentPath")
		);
		const pathWithoutParams = window.location.pathname;
		const pathWithParams = window.location.href.replace(process.env.NEXT_PUBLIC_SITE_URL, "");
		const path = pathWithParams.includes("/research-feed/filter")
			? pathWithParams
			: pathWithoutParams;
		// Set the previous path as the value of the current path
		// unless it's login/signup/forgot-pwd then keep existing prevPath
		const prevPath = isAuthPath ? storage.getItem("prevPath") : storage.getItem("currentPath");
		// ignore if same path
		if (window.location.pathname === prevPath) return;
		// save the previous path
		if (prevPath) storage.setItem("prevPath", prevPath);
		// Set the current path value by looking at the browser's location object.
		storage.setItem("currentPath", path);

		let pageviewCount = hasCookie("pageviewCount") ? Number(getCookie("pageviewCount")) : 0;
		if (pageviewCount <= 3) {
			pageviewCount++;
		}
		setCookie("pageviewCount", pageviewCount, { maxAge: 30 * 60 * 60 * 24 });

		let salesPageViewCount = hasCookie("salesPageViewCount")
			? Number(getCookie("salesPageViewCount"))
			: 0;

		if (salesPageViewCount <= 3) {
			salesPageViewCount++;
		}
		setCookie("salesPageViewCount", salesPageViewCount, { maxAge: 30 * 60 * 60 * 24 });
	}, []);

	React.useEffect(() => {
		if (
			pathname === "/" ||
			pathname.includes("thankyou") ||
			pathname.includes("members") ||
			pathname.includes("checkout") ||
			pathname === "/product-survey/" ||
			pathname === "/login/"
		) {
			return;
		} else if (
			user &&
			!user?.survey?.includes("member") &&
			isAfter(parseISO(user?.created_date), parseISO("2022-10-01")) &&
			subscription?.is_active
		) {
			// router.push("/product-survey/");
		}
	}, [pathname, user, subscription]);

	React.useEffect(() => {
		if (user) {
			window.dataLayer = window?.dataLayer || [];
			window.dataLayer?.push({
				event: "set_user_id",
				user_id: user?.id,
			});
			window.dataLayer?.push({
				"dlv-paid_status": paid,
			});
		}
	}, [paid, user]);

	React.useEffect(() => {
		document.dispatchEvent(new Event("rm:rerun")); // re-run rightmessage on page change
	}, [pathname]);

	return (
		<>
			<Script id="clarity-script">{clarityScript}</Script>
			<Script id="mixpanel-script">{mixpanelScript}</Script>
			{!!gtmId && !noGtm && <Script id="google-tag-manager">{gtmScript}</Script>}
			{!!bentoSiteUuid && (
				<Script id="bento-script" src={`https://fast.bentonow.com?site_uuid=${bentoSiteUuid}`} />
			)}
			{!!easyaskApiKey && (
				<script
					id="easyask"
					src="https://web.easyask.io/static/js/question-loader.js"
					data-api-key={easyaskApiKey}
					defer
				></script>
			)}
			<Script id="sumo" key={user ? `${pathname}-user` : pathname}>
				{`
				(function(s,u,m,o,j,v){j=u.createElement(m);v=u.getElementsByTagName(m)[0];j.async=1;j.src=o;j.dataset.sumoSiteId='${process.env.NEXT_PUBLIC_SUMO_SITE_ID}';v.parentNode.insertBefore(j,v)})(window,document,'script','//load.sumome.com/');
				`}
			</Script>
			<Script id="rightmessage" strategy="lazyOnload">
				{`
					(function(p, a, n, d, o, b, c) {
						o = n.createElement('script'); o.type = 'text/javascript'; o.async = true; o.src = 'https://tb.rightmessage.com/'+p+'.js';
						b = n.getElementsByTagName('script')[0]; d = function(h, u, i) { var c = n.createElement('style'); c.id = 'rmcloak'+i;
							c.type = 'text/css'; c.appendChild(n.createTextNode('.rmcloak'+h+'{visibility:hidden}.rmcloak'+u+'{display:none}'));
							b.parentNode.insertBefore(c, b); return c; }; c = d('', '-hidden', ''); d('-stay-invisible', '-stay-hidden', '-stay');
						setTimeout(o.onerror = function() { c.parentNode && c.parentNode.removeChild(c); }, a); b.parentNode.insertBefore(o, b);
					})('${process.env.NEXT_PUBLIC_RIGHTMESSAGE_ID}', 20000, document);
				`}
			</Script>
		</>
	);
}
